import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Moment from 'moment'

import { button } from 'utils/styles'

export default class SentMessage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    Moment.locale('en')
    let { message } = this.props
    return (
      <div className="message-body">
        {message && (
          <>
            <p className="hint-text">{message}</p>
            <p className="sent-time"> {Moment().fromNow()}</p>
          </>
        )}
      </div>
    )
  }
}
