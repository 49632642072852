import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { button, colors } from 'utils/styles'
import ReplyMessage from './ReplyMessage'
import SentMessage from './SentMessage'
import { UserBlurb } from 'components/shared/Users/UserBlurb'

const SubmitBtn = styled('a')`
  ${button.purple};
  ${button.big};
`

const Alert = styled('span')`
  border-radius: 4px;
  color: ${colors.brand};
  padding: 6px 10px;
  background-color: ${colors.brandLight};
  font-weight: 600;
  text-transform: lowercase;
  font-size: 11px;
`

export default class MessageForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      sentMessage: '',
      sending: false,
      success: false,
    }
  }

  _submit = async e => {
    e.preventDefault()
    this.setState({
      success: false,
      sending: true,
      sentMessage: this.state.message,
      error: null,
    })
    try {
      const error = await this.props.onSubmit({ message: this.state.message })
      if (error) {
        console.log(error)
        return this.setState({ error, sending: false })
      }
      this.setState({ success: true, sending: false, message: '' })
    } catch (error) {
      console.log(error)
      this.setState({ error, sending: false, success: false })
    }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    let { reply, recipient } = this.props || {}
    console.log('recipient', recipient)
    let { sending, success, message, sentMessage, error } = this.state
    return (
      <section className="form-page-container">
        <div className="message-container">
          <ReplyMessage reply={reply} />
          {success && <SentMessage message={sentMessage} />}
          <div className="message-column">
            <UserBlurb user={recipient} />
            <div className="alert-container">
              {success && <Alert>Message sent successfully</Alert>}
              {error && <Alert>Error sending message</Alert>}
            </div>
            <fieldset className="fieldset fieldset-with-label profile-field">
              <textarea
                type="text"
                name="message"
                className="message-input"
                id="message"
                required
                maxLength="450"
                rows="3"
                style={{ marginBottom: -15 }}
                value={message}
                onChange={this.onChange}
              />
            </fieldset>
            <div className="bottom-btn-container step-1">
              <div>
                <SubmitBtn onClick={this._submit}>
                  {sending ? 'Sending...' : 'Send'}
                </SubmitBtn>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
