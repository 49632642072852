import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Moment from 'moment'

import { button } from 'utils/styles'

export default class ReplyMessage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    Moment.locale('en')
    let { reply } = this.props
    return (
      <div className="reply-body">
        {reply.body && (
          <>
            <p className="hint-text">{reply.body}</p>
            <p className="sent-time">
              {' '}
              {Moment(reply.createdAt.toDate()).format('LLLL')}
            </p>
          </>
        )}
      </div>
    )
  }
}
